import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Link from 'gatsby-plugin-transition-link'

export default () => {
  return (
    <Layout headerClass="c-navbar--article" footerClass="c-footer--article">
      <SEO
        title="Page Not Found"
      />
      <div className="c-content">
        <div className="container-fluid">
          <div className="row">
            <div className="c-content__container">
              <h1 className="c-content__title">Page Not Found</h1>
              <div className="c-content__text cms-content">
                <p>This might be because:</p>
                <ul>
                  <li>You may have typed the web address incorrectly. Please
                    check the address and spelling ensuring that it does not
                    contain capital letters or spaces.
                  </li>
                  <li>It is possible that the page you were looking for may
                    have been moved, updated or deleted.
                  </li>
                </ul>
                <p><Link to="/">Back to homepage</Link></p>
              </div>
            </div>
          </div>
        </div>
        <div className="c-content__close">
          <Link to="/" className="c-content__close-link c-btn">
            <svg fill="none" viewBox="0 0 30 31">
              <path stroke="#fff" strokeWidth="2" d="M.9914 1.2929l28.2843 28.2843M28.9914 1.7071L.7071 29.9914"/>
            </svg>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
